import {connect} from 'react-redux'
import Link from 'next/link'
import Slider from 'react-slick'
const Info = ({data, mobile, api = ''}) => {
  if (!data || data && !data.length) return null

  const item = (el, size, big) => <Link href={el.url} key={`item${el.url}`} >
    <a className={`index__info__item index__info__item--${size}`}>
      <div className="index__info__item__body">
        {!big && <div className="index__info__item__img"><span className="lazzy" data-image={`${api}${el.img}`} /></div>}
        <div className="index__info__item__text">
          {big && <div dangerouslySetInnerHTML={{__html: el.text}} />}
          <div className="index__info__item__title" dangerouslySetInnerHTML={{__html: el.title}} />
          {!big && <div dangerouslySetInnerHTML={{__html: el.text}} />}
        </div>
        {big && <div className="index__info__item__img"><span className="lazzy" data-image={`${api}${el.img}`} /></div>}
      </div>
    </a>
  </Link>

  const itemMobile = (el, size, big) => <Link href={el.url} key={`item${el.url}`} >
    <a className={`index__info__item index__info__item--${size}`}>
      <div className="index__info__item__body">
        {!big && <div className="index__info__item__img"><span className="lazzy" style={{backgroundImage: `url(${api}${el.img})`}} /></div>}
        <div className="index__info__item__text">
          {big && <div dangerouslySetInnerHTML={{__html: el.text}} />}
          <div className="index__info__item__title" dangerouslySetInnerHTML={{__html: el.title}} />
          {!big && <div dangerouslySetInnerHTML={{__html: el.text}} />}
        </div>
        {big && <div className="index__info__item__img"><span className="lazzy" style={{backgroundImage: `url(${api}${el.img})`}} /></div>}
      </div>
    </a>
  </Link>

  const settings = {
    dots: true,
    lazyLoad: true,
  }

  if (mobile) return (
    <div className="index__info-wrap">
      <div className="index__info frame animate"><Slider {...settings}>{data.map(el => itemMobile(el, 'mobile') )}</Slider></div>
      <Link href="/recommended"><a className="btn btn--border no-border-link"><span>Посмотреть еще</span></a></Link>
    </div>
  )

  return (
    <div className="index__info-wrap">
      <div className="index__info frame animate">
        {data[0] && <div className="index__info__left">{item(data[0], 'large', true)}</div>}
        <div className="index__info__right">
          {data[1] && item(data[1], 'large')}
          <div>
            {data[2] && item(data[2], 'middle')}
            {data[3] && item(data[3], 'small')}
          </div>
        </div>
      </div>
      <Link href="/recommended"><a className="btn btn--border no-border-link"><span>Посмотреть еще</span></a></Link>
    </div>
  )
}

export default connect(
  state => ({mobile: state.mobile}), {}
)(Info)
