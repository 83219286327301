import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'

const InputSearchWord = ({api, setSearch, defaultValue, changeSearchValue, home, val, apply}) => {
  const { transcript } = useSpeechRecognition()
  const input  = useRef(null);

  const [data, setData] = useState([]);
  const [dataOriginal, setDataOriginal] = useState([]);
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(transcript)
    if(changeSearchValue){
      changeSearchValue(transcript);
    }
  }, [transcript]);

  useEffect(() => {
    axios
    .get(api + `/ajax/json.php?page=wordsearchsuggestions&query=${value}&realty`)
    .then(({ data }) => data)
    .then(response => {
      //console.log(api + `/ajax/json.php?page=wordsearchsuggestions&query=${value}&realty`, '111111');

      //console.log(response);
      if(Array.isArray(response.suggestions)){
        const tmp = [];
        response.suggestions.forEach(item => {
          tmp.push({ name: item.text, value: item.text })
        })
        setDataOriginal(response.suggestions);
        setData(tmp);
      }
    })
  },[value])

  const createSpeech = () => {
    if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
      return null;
    }

    return (
      <span
        className="voice-field__mike ico-8"
        onClick={() => {
          SpeechRecognition.startListening({ language: 'ru-RU' })
        }}
      />
    )
  }

  return(
    <>
      <div className="input-search-word">
        {createSpeech()}
        <Autocomplete
          open={true}
          openOnFocus={true}
          filterOptions={options => options}
          options={data}
          renderInput={(params) => <TextField {...params} placeholder="Введите адрес или название ЖК" inputRef={input} />}
          getOptionLabel={option => option.name}
          defaultValue={defaultValue}
          clearOnBlur={true}
          inputValue={val}
          onKeyPress={e => {
            if(e.key === 'Enter') {
              if(apply){
                apply();
              }
            }
          }}
          onChange={(e, v, reason) => {
            if(reason === 'select-option'){
              setSearch(dataOriginal.filter(item => item.text == v.value)[0]);
            }
            if(reason === 'clear'){
              if(home){
                setSearch({});
              }

              if(changeSearchValue){
                changeSearchValue('');
              }
            }
          }}
          onInputChange={e => {
            if(e){
              if(e.target){
                setValue(e.target.value);

                if(changeSearchValue){
                  changeSearchValue(e.target.value);
                }
              }
            }
          }}
          noOptionsText="Ничего не найдено"
        />
      </div>
    </>
  )
}

export default InputSearchWord;
