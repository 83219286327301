import { useState } from 'react';
import { Range, Handle } from 'rc-slider';

const UiRangeField = ({defaultValue, min, max, onChange, title, className='', labels, onAfterChange, home}) => {
    const [focusMin, setFocusMin] = useState(false);
    const [focusMax, setFocusMax] = useState(false);
    const [step, setStep] = useState(1000000);
    const [dmax, setDmax] = useState(max);
    const numberFormat = new Intl.NumberFormat('ru-RU');
    const format = [numberFormat.format(defaultValue[0]), numberFormat.format(defaultValue[1])];

    const [smin, setSmin] = useState(min);


    const [change_min, setChange_min] = useState(min);
    const [change_max, setChange_max] = useState(max);
    const [change_drag, setChange_drag] = useState(0);

    let def_min = min;
    let def_max = max;

    const changeMin = value => {
        const tmp = [...defaultValue];
        tmp[0] = isNaN(parseInt(value)) ? 0 : parseInt(value);

        if(onChange){
            onChange(tmp);
        }
        if(onAfterChange){
            onAfterChange(tmp);
        }
    };



    const changeMax = value => {
        const tmp = [...defaultValue];
        tmp[1] = isNaN(parseInt(value)) ? 0 : parseInt(value);
        if(onChange){
            onChange(tmp);
        }
        if(onAfterChange){
            onAfterChange(tmp);
        }
    };

    const blurMin = value => {
        const tmp = [...defaultValue];

        if (value <= min) {
            tmp[0] = parseInt(min);
        } else if (value >= max) {
            tmp[0] = parseInt(max);
        } else {
            tmp[0] = parseInt(value);
        }
        if(onChange){
            onChange(tmp);
        }
        if(onAfterChange){
            onAfterChange(tmp);
        }
    };

    const blurMax = value => {
        const tmp = [...defaultValue];

        if (value <= tmp[0]) {
            tmp[1] = parseInt(tmp[0]);
        } else if (value >= max) {
            tmp[1] = parseInt(max);
        } else {
            tmp[1] = parseInt(value);
        }
        if(onChange){
            onChange(tmp);
        }
        if(onAfterChange){
            onAfterChange(tmp);
        }
    };
    /*
    function logslider(position, vals) {
        let minp = def_min;
        let maxp = def_max;

        let minv = Math.log(1000);
        //let maxv = Math.log(def_min);
        let maxv = Math.log(max/40);

        let scale = (maxv-minv) / (maxp-minp);

        return Math.round((Math.exp(minv + scale*(position-minp)))/1000)*1000;
    }

     */

    function logslider(position, values) {
        // position will be between 0 and 100
        var minp = def_min;
        var maxp = def_max;

        // The result should be between 1000 an 10000000
        var minv = Math.log(def_min);
        var maxv = Math.log(10000000);

        // calculate adjustment factor
        var scale = (maxv-minv) / (maxp-minp);

        let step_val = Math.exp(minv + scale*(position-minp));

        let step_val_length = (Math.round(step_val)+'').length;

        //console.log(step_val_length);
        let step_nulls = '1';

        for (let i = 0; i < (step_val_length-2); i++) { // выведет 0, затем 1, затем 2
            step_nulls += 0;
        }
        step_nulls = Number(step_nulls);
        if(step_nulls<1000){
            step_nulls = 1000;
        }
        //console.log(step_nulls);

        //let step = Math.round(step_val/step_nulls)*step_nulls;

        let step = 100000;
        let razn = values[1]-values[0];

        if(razn>=def_min && razn<=def_max){
            step = 5000000;
        }
        if(razn>=50000000 && razn<=100000000){
            step = 2500000;
        }
        if(razn>=30000000 && razn<=50000000){
            step = 1000000;
        }




        return step;
        //return Math.round(((Math.log(position)-minv) / scale + minp)/1000)*1000;
    }

    return (
        <div className={`filter-field ${className}`}>
            {title && <div className="filter-field__title">{title}</div>}
            <div className="filter-field__body">
                <div className="filter-input">
                    <input
                        value={(format[0] == 0 && focusMin) ? '' : format[0]}
                        onChange={e => {
                            changeMin(e.target.value.replace(/[^+\d]/g, ''))
                            setFocusMin(false);
                        }}
                        onBlur={e => {
                            blurMin(e.target.value.replace(/[^+\d]/g, ''))
                            setFocusMin(false);
                        }}
                        onFocus={() => {
                            changeMin(0)
                            setFocusMin(true);
                        }}
                        size={home ? format[0].toString().length : numberFormat.format(max).length}
                        maxLength={numberFormat.format(max).length}
                    />
                    <span />
                    <input
                        value={(format[1] == 0 && focusMax) ? '' : format[1]}
                        onChange={e => {
                            changeMax(e.target.value.replace(/[^+\d]/g, ''))
                            setFocusMax(false);
                        }}
                        onBlur={e => {
                            blurMax(e.target.value.replace(/[^+\d]/g, ''))
                            setFocusMax(false);
                        }}
                        size={home ? format[1].toString().length : numberFormat.format(max).length}
                        maxLength={numberFormat.format(max).length}
                        onFocus={() => {
                            changeMax(0)
                            setFocusMax(true);
                        }}
                    />
                </div>
            </div>
            <Range
                min={min}
                max={max}
                value={defaultValue}

                onChange={(values) => {

                    if(onChange){
                        onChange(values);

                        //setStep(logslider(values[1]-values[0], values));
                        setStep(logslider(change_drag == 0 ? values[0] : values[1], values));
                        /*
                        if(values[1]-values[0]<100000000){
                            console.log('7777777777777777');
                            setSmin(min + 100000000);
                        }

                         */

                        let values_n = [change_min, change_max];

                        if(change_drag===0){
                            values_n[0] = values[0];
                            setChange_min(values_n[0]);
                        }
                        if(change_drag===1){
                            values_n[1] = values[1];
                            if(values_n[1]+step>max){
                                values_n[1] = max;
                            }
                            setChange_max(values_n[1]);
                        }

                        onChange(values_n);
                        if(values_n[1] === max){
                            //console.log(values_n);
                            setTimeout(function (){
                                document.querySelector('.rc-slider-handle.rc-slider-handle-2').style.left = '100%';
                            },0)
                        }
                        if(values_n[1] === max && values_n[0] === min){
                            setTimeout(function (){
                                document.querySelector('.rc-slider-handle.rc-slider-handle-2').style.left = '100%';
                                document.querySelector('.rc-slider-track.rc-slider-track-1').style.width = '100%';
                            },0)
                        }


                        if(change_drag===0){
                            if(step<5000000){
                                //setSmin(200000000);
                                /*
                                console.log('111111111');
                                document.querySelector('.rc-slider').style.width = 'calc(150% - 10px)';
                                document.querySelector('.rc-slider').style.marginLeft = 'calc(-50% + 5px)';
                                //document.querySelector('.rc-slider').style.marginRight = '5px';
                                setTimeout(function (){
                                    document.querySelector('.rc-slider').style.width = 'calc(150% - 10px)';
                                    document.querySelector('.rc-slider').style.marginLeft = 'calc(-50% + 5px)';
                                    },0)
                                 */

                            }else{
                                //setSmin(min);

                                /*
                                document.querySelector('.rc-slider').style.width = '';
                                document.querySelector('.rc-slider').style.marginLeft = '';
                                setTimeout(function (){
                                    document.querySelector('.rc-slider').style.width = '';
                                    document.querySelector('.rc-slider').style.marginLeft = '';
                                },0)
                                 */

                            }
                        }





                    }
                }}
                onAfterChange={values => {
                    /*
                    if(onAfterChange){
                        onAfterChange(values);
                    }

                     */
                }}
                step={title.indexOf('Стоимость') !== -1 ? step : 1}
                allowCross={false}
                handle ={props =>{
                    if(props.dragging){
                        setChange_drag(props.index);
                    }
                    return (
                        <Handle key={`handle${props.className}`} {...props} >
                            {props.dragging !== 'false' ? <span>{props.value}</span> : ''}
                        </Handle>
                    );
                }}

            />
            {labels && (
                <div className="ui-range-slider-labels">
                    <div>{labels[0]}</div>
                    <div>{labels[1]}</div>
                </div>
            )}
        </div>
    )
}

export default UiRangeField;

